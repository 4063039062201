@import url("react-toastify/dist/ReactToastify.css");

/* SCROLL BAR CSS */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(108, 108, 108);
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(112, 112, 112);
}

::-webkit-scrollbar-thumb:active {
    background-color: rgb(128, 128, 128);
}

/* Buttons */
::-webkit-scrollbar-button:single-button {
    background-color: transparent;
    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 10px;
    width: 10px;
    background-position: 1px 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(108, 108, 108)'><polygon points='40,00 0,40 80,40'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='40,00 0,40 80,40'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='40,00 0,40 80,40'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 10px;
    background-position: 1px 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(108, 108, 108)'><polygon points='0,0 80,0 40,40'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 80,0 40,40'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 80,0 40,40'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 10px;
    width: 10px;
    background-position: 3px 1px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(108, 108, 108)'><polygon points='0,40 40,80 40,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,40 40,80 40,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,40 40,80 40,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 12px;
    width: 12px;
    background-position: 3px 1px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(108, 108, 108)'><polygon points='0,0 0,80 40,40'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,80 40,40'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,80 40,40'/></svg>");
}

/* Select dropdown border remove */
fieldset {
    border: 0 none !important;
    outline: 0 !important;
}

.shineText {
    padding: 0px 32px;
    color: #fff;
    background: linear-gradient(to right, #4d4d4d 0, #fff 10%, #4d4d4d 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 2s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
}

@keyframes shine {
    0% {
        background-position: 0;
    }
    60% {
        background-position: 120px;
    }
    100% {
        background-position: 180px;
    }
}

.spinBorder {
    --border-size: 100%;
    --border-angle: 0turn;
    border-radius: 50%;
    background-image: conic-gradient(from var(--border-angle), #213, #112 50%, #213),
        conic-gradient(from var(--border-angle), transparent 20%, #08f, rgb(36, 170, 96));
    background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)),
        cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-animation: spinBorder 2s ease-in-out infinite;
    animation: spinBorder 2s ease-in-out infinite;
}
@-webkit-keyframes spinBorder {
    to {
        --border-angle: 1turn;
    }
}
@keyframes spinBorder {
    to {
        --border-angle: 1turn;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}
